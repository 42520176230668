import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { CartService } from 'src/app/core/api/cart.service';
import { DetailsComponent } from '../details/details.component';
import { endOfMonth, startOfDay, subDays } from 'date-fns';

@Component({
  selector: 'app-cart-list',
  templateUrl: './cart-list.component.html',
  styleUrls: ['./cart-list.component.scss'],
})
export class CartListComponent implements OnInit {
  @Input() isFromDashboard: boolean = false;
  cartDetails: any[] = [];
  pageSize = 10;
  pageIndex = 1;

  isCartDetailsPageLoading: boolean = false;
  totalNumberOfCartList: number = 0;
  ranges = {
    Today: [new Date(), new Date()],
    'Last 3 Days': [startOfDay(subDays(new Date(), 2)), new Date()],
    'Last 7 Days': [startOfDay(subDays(new Date(), 6)), new Date()],
    'Last 15 Days': [startOfDay(subDays(new Date(), 14)), new Date()],
    'Last 30 Days': [startOfDay(subDays(new Date(), 29)), new Date()]
  }
  dates=[
    startOfDay(subDays(new Date(), 6)),
    new Date()
  ];

  constructor(
    private cartService: CartService,
    private drawerService: NzDrawerService
  ) {}
  ngOnInit(): void {
    this.toGetCardDetails();
    if(this.isFromDashboard){
      this.pageSize = 5;
      this.dates = [startOfDay(subDays(new Date(), 6)), new Date()];
    }
  }
  toGetCardDetails(skip = 0) {
    let params: any;
    params = {
      skip,
      limit: this.pageSize,
    };
    if(this.dates && this.dates.length > 0){
      params.startDate = this.dates[0];
      params.endDate = this.dates[1];
    }else{
      delete params.startDate;
      delete params.endDate;
    }
    this.isCartDetailsPageLoading = true;
    this.cartService.getAllCarts(params).subscribe(
      (result: any) => {
        this.cartDetails = result?.data;
        this.totalNumberOfCartList = result?.totalCount;
        this.isCartDetailsPageLoading = false;
      },
      (error: any) => {
        this.isCartDetailsPageLoading = false;
      }
    );
  }
  showBuyerDetails(data: any){
    this.drawerService.create({
      nzTitle: 'Buyer Details',
      nzContent: DetailsComponent,
      nzWidth: '80%',
      nzContentParams: {
        data: data
      }
    });
  }

  /* For Pagination */
  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex } = params;
    this.toGetCardDetails(pageSize * (pageIndex - 1));
  }


  onChange(result: Date[]): void {
    this.dates = result;
    this.toGetCardDetails();
  }
}
