<div nz-row class="text-right mb2" *ngIf="!isFromDashboard">
  <div nz-col nzSpan="24">
    <nz-range-picker [nzRanges]="ranges" [(ngModel)]="dates" (ngModelChange)="onChange($event)"></nz-range-picker>
  </div>
</div>

<nz-table
  #basicTable
  [nzData]="cartDetails"
  [nzLoading]="isCartDetailsPageLoading"
  [(nzPageSize)]="pageSize"
  [nzPageIndex]="pageIndex"
  [nzTotal]="totalNumberOfCartList"
  (nzQueryParams)="onQueryParamsChange($event)"
  [nzFrontPagination]="false"
  nzShowSizeChanger
  [nzShowPagination]="!isFromDashboard"
>
  <thead>
    <tr>
      <th>Buyer Name</th>
      <th>Email</th>
      <th>Phone</th>
      <th>Numbers of Item</th>
      <th>Date</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of basicTable.data">
      <td>{{ data?.buyerName }}</td>
      <td>{{ data?.buyerEmail }}</td>
      <td>{{ data?.buyerPhone }}</td>
      <td>{{ data?.items?.length }}</td>
      <td>{{ data.createdAt | date: "MMM d, y" }}</td>
      <td>
        <button
          nz-button
          nzType="primary"
          (click)="showBuyerDetails(data)"
          routerLinkActive="router-link-active"
          nz-tooltip
          nzTooltipTitle="View Details"
        >
          <span nz-icon nzType="eye" nzTheme="outline"></span>
        </button>
      </td>
    </tr>
  </tbody>
</nz-table>
