import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor() {}
  get apiUrl(): string {
    return 'https://api.auramjewellery.com';
  }

  get mediaBaseUrl(): string {
    return environment.production ? 'https://files.auramjewellery.com/' : 'https://files.auramjewellery.com/';
  }
}
