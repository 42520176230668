import { Injectable } from '@angular/core';
import { HttpRequestService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(
    private requestService:HttpRequestService
  ) { }
  getAllCarts(params: any={skip: 0, limit: 0}) {
    return this.requestService.request('get', `carts`, params);
  }
}
