<nz-descriptions nzTitle="Buyer Info">
  <nz-descriptions-item nzTitle="Buyer Name">{{
    data.buyerName
  }}</nz-descriptions-item>
  <nz-descriptions-item nzTitle="Mobile">{{
    data.buyerPhone
  }}</nz-descriptions-item>
  <nz-descriptions-item nzTitle="Email">{{
    data.buyerEmail
  }}</nz-descriptions-item>
  <nz-descriptions-item nzTitle="Address">
    {{ data.buyerAddress }}
  </nz-descriptions-item>
  <nz-descriptions-item nzTitle="Date">
    {{ data.createdAt | date: "MMM d, y"}}
  </nz-descriptions-item>
</nz-descriptions>
<nz-divider nzText="Item Details"></nz-divider>
<ng-container *ngFor="let data of data.items">
  <nz-descriptions nzTitle="" nzBordered>
    <nz-descriptions-item nzTitle="Item Name">
      <strong>
        {{ data.itemName }}
      </strong>
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="Making Cost">{{
      data.makingCharge | currency : "INR"
    }}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Metal Price">
      <span nz-typography nzType="success">Curent Price: {{data.metalRateDetails && data.metalRateDetails.rate | currency: 'INR'}}/gm</span>
      <br/>
      <span nz-typography nzType="warning">Old Price: {{itemCopyObj && itemCopyObj[data._id] &&  itemCopyObj[data._id].metalRateDetails && itemCopyObj[data._id].metalRateDetails.rate | currency: 'INR'}}/gm</span>
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="Final Price">
      <span nz-typography nzType="success">Curent Price: {{data.finalPrice && data.finalPrice | currency: 'INR'}}</span>
      <br/>
      <span nz-typography nzType="warning">Old Price: {{itemCopyObj && itemCopyObj[data._id] &&  itemCopyObj[data._id].finalPrice && itemCopyObj[data._id].finalPrice | currency: 'INR'}}</span>
    </nz-descriptions-item>
  </nz-descriptions>
  <br />
</ng-container>
