export const etAdminMenu = [
  {
    key: 'dashboard',
    title: 'Dashboard',
    route: '/main/dashboard',
    icon: 'dashboard',
    children: [],
  },
  {
    key: 'categories',
    title: 'Categories',
    route: '/main/categories',
    icon: 'question-circle',
    children: [],
  },
  {
    key: 'metal-price',
    title: 'Metal Price',
    route: '/main/metal-price',
    icon: 'dollar-circle',
    children: [],
  },
  {
    key: 'items',
    title: 'Item',
    route: '/main/item',
    icon: 'question-circle',
    children: [],
  },
  {
    key: 'collection',
    title: 'Collection',
    route: '/main/collection',
    icon: 'question-circle',
    children: [],
  },
  {
    key: 'banner',
    title: 'Banner',
    route: '/main/banner',
    icon: 'question-circle',
    children: [],
  },
  // {
  //   key: 'discounts',
  //   title: 'Discount',
  //   route: '/main/discounts',
  //   icon: 'percentage',
  //   children: [],
  // },
  {
    key: 'cart',
    title: 'Show Interest',
    route: '/main/cart',
    icon: 'shopping-cart',
    children: [],
  },
  {
    key: 'general-info',
    title: 'General Info',
    route: '/main/general-info',
    icon: 'info-circle',
    children: [],
  }
  // {
  //   key: 'order',
  //   title: 'Order',
  //   route: '/main/order',
  //   icon: 'question-circle',
  //   children: [],
  // },
  // {
  //   key: 'newsletter',
  //   title: 'Newsletter',
  //   route: '/main/newsletter',
  //   icon: 'user',
  //   children: [],
  // }

];
