import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  @Input() data: any;
  itemCopyObj: any = {};
  constructor() { }

  ngOnInit(): void {
    if(this.data && this.data.itemCopy && this.data.itemCopy.length > 0){
      this.data.itemCopy.forEach((element: any) => {
        this.itemCopyObj[element._id] = element;
      }
      );
    }
  }

}
